<template>
  <div id="app">
    <b-navbar toggleable="md" type="dark" class="nav-background">
      <b-navbar-brand>Enlively</b-navbar-brand>
      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav>
          <b-nav-item href="/">Home</b-nav-item>
        </b-navbar-nav>

      </b-collapse>
      <div right-align><b-navbar-nav>
          <b-nav-item-dropdown text="English" disabled>
            <b-dropdown-item href="#">English</b-dropdown-item>
            <b-dropdown-item href="#">Hindi</b-dropdown-item>
            <b-dropdown-item href="#">Punjabi</b-dropdown-item>
          </b-nav-item-dropdown>
        <b-button size="lg" squared class="custom-register-button" href="/register">Register</b-button>
        <b-button size="lg" squared class="custom-red-button" href="/login">Login</b-button>
      </b-navbar-nav>

      </div>
    </b-navbar>
    <router-view></router-view>
  </div>
</template>

<script>
import Vue from 'vue';
import {
  BNavbar,
  BNavbarBrand,
  BNavbarToggle,
  BCollapse,
  BNavbarNav,
  BNavItem,
  BNavForm,
  BFormInput,
  BButton,
  BNavItemDropdown,
  BDropdownItem 
} from 'bootstrap-vue';
// helper function called mapGetters
export default {
  name: 'app',
  components: {
    BNavbar,
    BNavbarBrand,
    BNavbarToggle,
    BCollapse,
    BNavbarNav,
    BNavItem,
    BNavForm,
    BFormInput,
    BButton,
    BNavItemDropdown,
    BDropdownItem
  },
  data () {
    return {
      toggled: true,
      position: 'top',
      color: '#4285f4'
    }
  },
}

</script>

<style lang="scss">

@import url('https://fonts.googleapis.com/css?family=Lato:400,700');

body {
    background: #EEF1F4 !important;
    font-family: 'Lato', sans-serif !important;
  }


.nav-background {
    background: #353535;
    padding: 0 14px;
    p {
      color: #FFFFFF;
      font-size: 18px;
    }
  }

.spacer {
  flex-grow: 1; /* This will make the spacer take up the remaining space */
}

.custom-red-button {
  background-color: red !important;
  border-color: red !important;
  color: white !important;
}
.custom-red-button:hover {
  background-color: darkred !important;
  border-color: darkred !important;
}
.custom-register-button {
  background-color: #353535 !important;
  border-color: #353535 !important;
  color: white !important;
}
</style>