import Vue from 'vue';
import Router from 'vue-router';
import UserRegister from '../components/UserRegister.vue';
import UserRegisterComplete from '../components/UserRegisterComplete.vue';
import UserLogin from '../components/UserLogin.vue';
import WelcomeUser from '@/components/WelcomeUser.vue';
import UserAddOrganisation from '@/components/UserAddOrganisation.vue';
// import { component } from 'vue/types/umd';

Vue.use(Router);

const routes = [
  {
    path: '/register',
    name: 'UserRegister',
    component: UserRegister
  },
  {
    path: '/complete',
    name: 'UserRegisterComplete',
    component: UserRegisterComplete
  },
  {
    path: '/login',
    name: 'UserLogin',
    component: UserLogin
  },
  {
    path: '/welcome',
    name: 'WelcomeUser',
    component: WelcomeUser
  },
  {
    path: '/add-organisation',
    name: 'AddOrganisation',
    component: UserAddOrganisation
  }
];

const router = new Router({
  mode: 'history',
  routes
});

export default router;
