<template>
  <div class="container">
    <h2>Login</h2>
    <form @submit.prevent="login">
      <div class="form-group">
        <label for="username">Username:</label>
        <input type="text" id="username" v-model="username" class="form-control">
      </div>
      <div class="form-group">
        <label for="password">Password:</label>
        <input type="password" id="password" v-model="password" class="form-control">
        <div class="button-container">
          <button type="button" class="btn btn-primary" @click="register">Register</button>
          <button type="button" class="btn btn-primary" @click="reset">Reset</button>
          <button type="submit" class="btn btn-primary">Login</button>
        </div>
      </div>
    </form>
    <p v-if="errorMessage" class="text-danger">{{ errorMessage }}</p>
    <MessageBox ref="messageBox" :message="message" />
  </div>
</template>

<script>
import axios from 'axios';
import { mapActions } from 'vuex';
import MessageBox from './MessageBox.vue';

export default {
  name: 'UserLogin',
  data() {
    return {
      username: '',
      password: '',
      visitorIdentifier: '',
      errorMessage: '',
      message: ''  // Add the missing message property
    };
  },
  components: {
    MessageBox
  },
  created() {
    this.visitorUniqueId = this.generateRandomString(10);
    this.fetchVisitorIdentifier();
  },
  methods: {
    ...mapActions(['getCookie']),

    async fetchVisitorIdentifier() {
      this.visitorIdentifier = await this.getCookie('visitorIdentifier');
    },
    setCookie(key, email) {
      const expirationDate = new Date();
      expirationDate.setDate(expirationDate.getDate() + 7);
      const cookieString = `${key}=${email}; expires=${expirationDate.toUTCString()}; path=/`;
      document.cookie = cookieString;
    },
    generateRandomString(length) {
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      let result = '';
      const charactersLength = characters.length;
      for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
      }
      return result;
    },
    async login() {
      try {
        const params = new URLSearchParams();
        params.append('username', this.username);
        params.append('password', this.password);

        if (!this.visitorIdentifier) {
          this.setCookie('visitorIdentifier', this.visitorUniqueId);
          this.visitorIdentifier = this.visitorUniqueId;
        } 

        params.append('track_id', this.visitorIdentifier);

        const response = await axios.post(
          `${this.$store.state.backend_url}/authorization/token`,
          params,
          { withCredentials: true }
        );
        this.setCookie('registrationEmail', this.username)
        this.$router.push('/welcome');
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.errorMessage = error.response.data.detail;
          console.log(this.errorMessage);
        } else {
          console.error(error);
        }
      }
    },
    register() {
      this.$router.push('/register');
    },
    reset() {
      this.$router.push('/reset-password');
    }
  }
};
</script>

<style scoped>
/* Ensure form takes up the necessary space */
.login-form {
  position: relative;
  padding-bottom: 50px; /* Space for buttons */
}

/* Button Container: flexbox for button layout */
.button-container {
  display: flex;
  margin-top: 15px;
  width: 20%;
  height: calc(1.5em + 0.75rem + 2px);
  position: absolute;
  line-height: 1.5;
  left: auto;
}

/* Ensure consistent button styling */
.button-container .btn {
  flex: 1;
  margin: 1 100px;
  width: 10%;
}
</style>
